<template>
  <div class="creditView">
    <van-nav-bar :title="$t('信用中心')" left-arrow @click-left="onClickLeft" />
    <div style="text-align:center">
      <div id="myChart" class="charts-box" style="width: 100%;height: 350px;" />
    </div>
    <van-cell icon="info" :title="$t('信用说明')" @click="toCreditDetail" is-link :value="$t('信用记录')" />
    <div class="info" style="padding: 0px 16px 16px;">
      <p v-html="$t('credit1')"></p>
      <p v-html="$t('credit2')"></p>
      <p v-html="$t('credit3')"></p>
      <p v-html="$t('credit4')"></p>
      <p v-html="$t('credit5')"></p>
      <p v-html="$t('credit6')"></p>
      <p v-html="$t('credit7')"></p>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      current: 560,
      credit: this.$store.getters.userinfo.credit,
    };
  },
  mounted() {
    this.draw();
  },
  methods: {
    onClickLeft() {
      this.$router.push('/main/user');
    },
    toCreditDetail() {
      this.$router.push('/main/user/creditDetail');
    },
    draw() {
      const echarts = require('echarts/lib/echarts');
      require('echarts/lib/chart/gauge');
      const myChart = echarts.init(document.getElementById('myChart'));
      myChart.setOption({
        tooltip: {
          formatter: '{a} <br/>{b} : {c}%',
        },
        toolbox: {
          feature: {
            restore: {},
            saveAsImage: {},
          },
        },
        series: [
          {
            min: 0,
            max: 100,
            name: '业务指标',
            type: 'gauge',
            axisLine: {
              // 坐标轴线
              lineStyle: {
                // 属性lineStyle控制线条样式
                color: [
                  [0.5, '#1e90ff'],
                  [0.8, 'lime'],
                  [1, '#ff4500'],
                ],
                width: 8,
                shadowColor: '#fff', //默认透明
                shadowBlur: 5,
              },
            },
            title: {
              textStyle: {
                // 其余属性默认使用全局文本样式，详见TEXTSTYLE
                fontWeight: 'bolder',
                fontSize: 14,
                color: '#999',
                shadowColor: '#fff', //默认透明
                shadowBlur: 10,
              },
            },
            detail: { formatter: '{value}' },
            data: [{ value: this.credit, name: this.$t('我的信用') }],
          },
        ],
      });
    },
  },
};
</script>
<style lang="stylus">
.creditView
  .van-cell
    background-color: #151d31;
    .van-cell__left-icon
      color: #4087f1;
    .van-cell__title
      color: #4087f1;
      font-weight: 600;
  .info
    margin-top: .3rem;
    padding: 0px 8px 8px;
    line-height: 0.466rem;
    font-size: 0.36rem;
    p
     color #ffffff
</style>
